.marker {
  position: absolute;
  height: 300px;
  width: 300px;
  background-image: url(/assets/images/A_01_01_spot_v02.png);
  /* -150px / -250px is the location of the point the marker points to */
  transform: translate(-150px, -250px);
  background-repeat: no-repeat;
  cursor: pointer;
}
