@mixin gradientOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: contain;
  pointer-events: none;
}
.top {
  @include gradientOverlay();
  background-position: top center;
  background-image: url(/assets/images/A_04_01_gradient_oben.png);
}

.bottom {
  @include gradientOverlay();
  background-position: bottom center;
  background-image: url(/assets/images/A_04_01_gradient_unten.png);
}

.both {
  @include gradientOverlay();
  background-image: url(/assets/images/A_04_01_gradient_unten.png),
    url(/assets/images/A_04_01_gradient_oben.png);
  background-position: bottom center, top center;
}
