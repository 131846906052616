@import "assets/stylesheets/fonts";

.buttomButtonBar {
  display: flex;
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 13%;
  z-index: 0;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-right: 30px;
  pointer-events: all;
  flex-direction: column;
  & > div {
    margin: 10px;
  }
}

.typewriterAndButtonsLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;

  .topContainer {
    position: absolute;
    top: 50px;
    width: 60%;
  }

  .bottomContainer {
    position: absolute;
    bottom: 100px;
    width: 70%;
  }

  .bottomButtonBar {
    pointer-events: all;
  }
}
