.userInputLayout {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  color: white;
  font-family: "Dimbo-Regular";
  flex-direction: column;
  align-items: center;

  .progress {
    border-style: solid;
    border-color: #00436a;
    z-index: 0;
    height: 2vh;
    width: 25vh;
  }

  progress {
    background-color: #00436a;
    border-radius: 16px;
  }
  progress::-webkit-progress-bar {
    background-color: #00436a;
    border-radius: 16px;
  }
  progress::-webkit-progress-value {
    background-color: white;
    border-radius: 16px;
  }
  progress::-moz-progress-bar {
    background-color: white;
    border-radius: 16px;
  }
  .subline {
    color: white;
    font-family: "Dimbo-Regular";
    font-size: 2.5vh;
    margin: 0.3em 0;
  }

  .progressContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5em;
  }

  .scroll {
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border-style: solid;
    border-color: transparent;
    height: 100%;
    justify-content: center;
  }

  .userInputLayoutContent {
    height: 50%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
    font-size: 5vw;
    font-weight: normal;
    margin-bottom: 0.2em;
    line-height: 5vw;
    width: 85%;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: normal;
    text-align: left;
    width: 100%;
  }

  .scrollPadding {
    height: 75px;
  }
}
