.outro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding-top: 6vh;

  .backgroundImage {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .trioDancing {
    position: absolute;
    height: 53vh;
    top: 0;
  }

  .headline {
    z-index: 1;
    color: white;
    font-family: "Dimbo-Regular";
    font-size: 9vh;
    margin-top: 1.5em;
    text-transform: uppercase;
  }

  .progress {
    border-style: solid;
    border-color: #00436a;
    z-index: 0;
    height: 3vh;
    width: 48vh;
  }

  progress {
    background-color: #00436a;
    border-radius: 16px;
  }
  progress::-webkit-progress-bar {
    background-color: #00436a;
    border-radius: 16px;
  }
  progress::-webkit-progress-value {
    background-color: white;
    border-radius: 16px;
  }
  progress::-moz-progress-bar {
    background-color: white;
    border-radius: 16px;
  }

  .sublineOuter {
    z-index: 1;
    margin-top: 1.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .subline {
      color: white;
      font-family: "Dimbo-Regular";
      font-size: 2.5vh;
      margin: 0.1em 0;
    }
  }

  .replayButton {
    z-index: 1;
    cursor: pointer;
    width: 16vh;
    margin-top: 1.5vh;
  }

  .remark {
    color: white;
    z-index: 1;
    margin-bottom: 1vh;
    font-family: "Dimbo-Regular";
    font-size: 2.5vh;
  }
}
