.mapContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.map {
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  background-size: auto auto;
}
