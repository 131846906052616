.explanationLayout {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  color: white;
  font-family: "Dimbo-Regular";
  flex-direction: column;
  align-items: center;

  .explanationLayoutInner {
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border-style: solid;
    border-color: transparent;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 5vw;
    margin-bottom: 0;
    font-weight: normal;
    max-width: 70%;
    line-height: 5vw;
  }

  h3 {
    font-size: 2vw;
    font-weight: normal;
  }

  span {
    font-size: 1.5vw;
  }
}

.explanationText {
  display: flex;
  flex-direction: column;
  font-family: "Roboto-Condensed";

  .uppercase {
    text-transform: uppercase;
    text-align: left;
  }

  li,
  p {
    font-size: 1.5vw;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 70%;
  -webkit-animation: fadein 1.8s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.8s; /* Firefox < 16 */
  -ms-animation: fadein 1.8s; /* Internet Explorer */
  -o-animation: fadein 1.8s; /* Opera < 12.1 */
  animation: fadein 1.8s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation-fill-mode: forwards; /* Firefox < 16 */
  -ms-animation-fill-mode: forwards; /* Internet Explorer */
  -o-animation-fill-mode: forwards; /* Opera < 12.1 */

  .contentImage {
    object-fit: contain;
    max-width: 100%;
    width: 100%;
    max-height: 65vh;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.explanationTextSingle {
  margin: 0 6%;

  .explanationTextHeadline {
    font-family: "Roboto-Condensed-Bold";
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .explanationTextEntries {
    font-family: "Roboto-Condensed";
  }
}

.explanationTextTopContainer {
  display: flex;
  margin-bottom: 16px;
  font-size: 24px;

  .explanationTextTop {
    max-width: calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 0 6%;
  }

  .explanationTextHeadline {
    margin-bottom: 16px;
    font-family: "Roboto-Condensed-Bold";
    text-transform: uppercase;
  }

  .explanationTextEntries {
    display: flex;
    flex-direction: column;
    font-family: "Roboto-Condensed";
  }
}

.scrollPadding {
  height: 75px;
}

.explanationTextLevelc {
  width: calc(100% - 18%);
  padding: 0 9%;
  font-size: 1.5vw;
  font-family: "Roboto-Condensed";
}

.explanationTextD {
  font-family: "Roboto-Condensed";
  display: flex;
  flex-direction: column;

  .uppercase {
    text-transform: uppercase;
    text-align: left;
  }

  p {
    color: white;
    font-size: 1.5vw;
  }
}
