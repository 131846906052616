.button {
  width: auto;
  height: 100%;
  padding: 3px 0;

  img {
    height: 100%;
    width: auto;
  }

  &:hover {
    cursor: pointer;
  }
}

.startPage {
  padding: 4px 8px;
  border-radius: 26px;
  width: 9.5vw;
  height: auto;
  margin-left: 4px;
  margin-right: 4px;
  font-family: "Dimbo-Regular";
  text-transform: uppercase;
}
