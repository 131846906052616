.footer {
  width: 100%;

  .footerTop {
    background-color: #2f2f2f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;

    .inner {
      max-width: 1180px;
      width: 100%;
      display: flex;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .jelly {
        width: 25%;
        cursor: pointer;

        img {
          object-fit: contain;
          width: 100%;
          cursor: pointer;
        }

        @media (max-width: 800px) {
          display: none;
        }
      }

      .goJelly,
      .getInTouch {
        width: 25%;
        padding: 0 10px;
        font-family: "Roboto-Condensed";
        font-size: 13px;

        @media (max-width: 800px) {
          width: 33.33%;
        }

        @media (max-width: 600px) {
          width: calc(100% - 20px);
        }

        h4 {
          color: #acafd9;
          font-size: 20px;
          font-weight: 600;
        }

        p {
          color: #eee;
          font-size: 13px;
        }

        a {
          color: #eee;
          line-height: inherit;
          text-decoration: none;
          &:hover {
            color: #1f9ed9;
          }
        }
      }

      .eu {
        width: 25%;
        font-family: "Roboto-Condensed";
        font-size: 13px;

        @media (max-width: 800px) {
          width: 33.33%;
        }

        @media (max-width: 600px) {
          width: 100%;
        }

        .euContent {
          color: #eee;

          img {
            object-fit: contain;
            margin-right: 13px;
            margin-bottom: 13px;
            float: left;
          }
        }

        h4 {
          color: #acafd9;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .footerBottom {
    background-color: #202020;
    padding: 8px;
    display: flex;
    justify-content: flex-end;

    .copyright {
      font-size: 11px;
      color: #eee;
      margin-right: 10px;
    }
  }
}

.headerImage {
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: initial;
  position: relative;

  .headerText {
    color: white;
    font-family: "Dimbo-Regular";
    text-transform: uppercase;
    font-size: 70px;

    @media (max-width: 1400px) {
      font-size: 5vw;
    }
  }
}

.outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    z-index: 9999;
    width: 9.5vw;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 2vw;
  }

  .links {
    position: absolute;
    top: 0;
    z-index: 1;
    right: 0;
    margin: 2vw;

    a {
      font-size: 16px;

      @media (max-width: 1130px) {
        font-size: 1.2vw;
      }
    }
  }

  .content {
    width: 60%;
    margin: 3em 0;

    ul {
      margin-top: 0;
    }

    .contentHeadline {
      display: flex;
      justify-content: center;
      font-family: "Roboto-Condensed-Bold";
      font-weight: bold;
      font-size: 2.5vw;
      margin: 1em 0;
    }

    .contentText {
      font-family: "Roboto-Condensed";
      font-size: 17px;
      min-height: calc(65vh - 440px);

      strong {
        font-size: 22px;
      }

      @media (max-width: 1130px) {
        font-size: 1.5vw;

        strong {
          font-size: 2vw;
        }
      }

      a {
        color: #0090ff;
        text-decoration: none;

        &:visited {
          text-decoration: none;
          color: #0090ff;
        }
      }
    }
  }
}

.otherSidesImages {
  display: flex;

  .otherSidesImagesJelly {
    object-fit: contain;
    width: 25%;
    max-width: 366px;
  }

  .otherSidesImagesUni {
    object-fit: contain;
    width: 30%;
    max-width: 30%;
    display: flex;
    flex: 1;
  }
}
