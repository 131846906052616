@font-face {
  font-family: "Dimbo-Regular";
  src: url("/assets/fonts/Dimbo-Regular.ttf.woff") format("woff"),
    url("/assets/fonts/Dimbo-Regular.ttf.svg#Dimbo-Regular") format("svg"),
    url("/assets/fonts/Dimbo-Regular.ttf.eot"),
    url("/assets/fonts/Dimbo-Regular.ttf.eot?#iefix")
      format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

$dimbo-font: Dimbo-Regular;

@font-face {
  font-family: "Roboto-Condensed";
  src: url("/assets/fonts/Roboto-Condensed.ttf");
  font-weight: normal;
  font-style: normal;
}

$roboto-font: Roboto-Condensed;

@font-face {
  font-family: "Roboto-Condensed-Bold";
  src: url("/assets/fonts/Roboto-BoldCondensed.ttf");
  font-weight: normal;
  font-style: normal;
}

$roboto-bold: Roboto-Condensed-Bold;
