@import "form";
@import "fonts";
body,
html,
#gameContainer {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.game {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .logo {
    z-index: 9999;
    width: 9.5vw;
    position: fixed;
    left: 0px;
    top: 0px;
    margin: 2.5vw;
    cursor: pointer;
  }
}

.orientation-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  color: white;
  display: flex;
  font-family: "Dimbo-Regular";
  font-size: 24pt;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 9999;
  flex-direction: column;

  .logo {
    z-index: 9999;
    width: 25vw;
    position: fixed;
    left: 0px;
    top: 0px;
    margin: 7vw;
  }

  .logo-simple {
    width: 70vw;
    margin-top: 3em;
  }

  .background-image {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .rotate-icon {
    width: 20vw;
    margin: 12px 0;
  }

  .content {
    margin-top: 4em;
  }

  .headline {
    font-family: "Dimbo-Regular";
    color: white;
    margin-top: 16px;
    text-transform: uppercase;
    font-size: 8.5vw;
  }

  .subline {
    color: white;
    font-family: "Dimbo-Regular";
    font-size: 5.5vw;
    margin-bottom: 1em;
  }

  .links {
    z-index: 9999;
    position: fixed;
    right: 0px;
    top: 0px;
    margin: 5vw;
    display: flex;

    .link {
      color: white;
      font-family: "Roboto-Condensed";
      text-transform: uppercase;
      font-size: 3vw;
    }
  }
}

.start-screen {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  flex-direction: column;

  .background-image {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .logo {
    z-index: 9999;
    width: 9.5vw;
    position: fixed;
    left: 0px;
    top: 0px;
    margin: 2.5vw;
  }

  .links {
    z-index: 9999;
    position: fixed;
    right: 0px;
    top: 0px;
    margin: 3vw;
    display: flex;

    .link {
      font-size: 1.2vw;
      color: white;
      font-family: "Roboto-Condensed";
      text-transform: uppercase;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 21%;

    .logo-simple {
      position: relative;
      width: 32vw;
      height: 5.1vw;
    }

    .headline {
      font-family: "Dimbo-Regular";
      color: white;
      margin-top: 16px;
      text-transform: uppercase;
      font-size: 3vw;
    }

    .subline {
      color: white;
      font-family: "Dimbo-Regular";
      font-size: 1.5vw;
    }

    .play-now-button {
      margin-top: 30px;
      width: 9.5vw;
      cursor: pointer;
    }

    .rotate-icon {
      width: 8vw;
      margin: 12px 0;
    }

    .rotate-text {
      color: white;
      font-family: "Dimbo-Regular";
      font-size: 3vw;
      max-width: 25vw;
      text-align: center;
    }
  }
}

.input-container {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.Typewriter__cursor {
  color: white;
  font-family: $dimbo-font;
  font-size: 3vw;
  line-height: 3.3vw;
}

.link {
  cursor: pointer;
  font-size: 2vw;
  font-family: "Roboto-Condensed";
  text-transform: uppercase;
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.typewriter-recomendation {
  margin-top: 1em;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/assets/scenes/A_01/A_05_04_bg_v02.jpg);
  font-family: "Dimbo-Regular";
  color: white;
  flex-direction: column;

  .headline {
    font-size: 15vw;
  }

  .subline {
    font-size: 4vw;
    text-align: center;
  }

  .logo {
    z-index: 9999;
    width: 9.5vw;
    position: fixed;
    left: 0px;
    top: 0px;
    margin: 2.5vw;
  }
}

.startpage-lngs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}
