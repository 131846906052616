.decision {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6vw;
  margin: 6px 0;
  font-family: "Roboto-Condensed-Bold";
  text-transform: uppercase;

  .before {
    margin-right: 2rem;
    flex: 1;
    text-align: right;
  }
  .after {
    margin-left: 2rem;
    flex: 1;
    text-align: left;
  }
}
.slider {
  width: 8rem;
  max-width: 500px;
  height: 40px;
  background-color: rgba(128, 128, 128, 0.7);
  border-radius: 1rem;
  .track {
    top: 20px;
    height: 10px;
  }

  .thumb {
    font-size: 0.9em;
    background-image: url(/assets/images/ui/A_05_01_switch.png);
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    cursor: pointer;
    color: transparent;
    border: none;
    box-sizing: border-box;
    top: 1px;
    width: 58px;
    height: 48px;
    line-height: 38px;
    z-index: 0 !important;

    &:focus {
      outline: none;
    }
  }
}
